<template>

  <div class="container user-section">
		<div class="row">
			<div class="col-12">

        <b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>
        <b-overlay variant="white" :show="sending" no-wrap></b-overlay>

        <b-alert variant="danger" :show="error? true : false">{{ error }}</b-alert>

        <form @submit.prevent="saveProfile" v-if="showform">
          <div class="form-group text-right">
            <button type="button" class="btn btn-delete noshadow" @click="confirmDelete = true" v-if="$parent.$parent.usergroup == 'Resident'"><i class="fas fa-trash"></i> Poista tili</button>
            <button class="btn btn-success" type="submit"><i class="fas fa-save"></i> Tallenna</button>
          </div>
          <div class="form-group mobile">
            <label class="form-label">Sukunimi</label>
            <input type="text" class="form-control" v-model="profile.extended.lastname" required />
          </div>
          <div class="form-group mobile">
            <label class="form-label">Etunimi</label>
            <input type="text" class="form-control" v-model="profile.extended.firstname" required />
          </div>
          <div class="form-group mobile">
            <label class="form-label">Sähköposti</label>
            <input type="email" class="form-control" v-model="profile.email" required />
          </div>
          <div class="form-group mobile">
            <label class="form-label">Puhelinnumero</label>
            <input type="text" class="form-control" v-model="profile.phone" required />
          </div>
          <div class="form-group mobile" v-if="$parent.$parent.usergroup == 'Resident' || $parent.$parent.usergroup == 'Property-manager'">
            <label class="form-label">Osoite</label>
            <input type="text" class="form-control" v-model="profile.address" required />
          </div>
          <div class="form-group mobile" v-if="$parent.$parent.usergroup == 'Resident'">
            <label class="form-label">Asunnon numero (esim. A 17)</label>
            <input type="text" class="form-control" v-model="profile.city" required />
          </div>
        </form>

        <p><router-link to="/vaihda-salasana">Vaihda salasana</router-link></p>

        <b-overlay :show="confirmDelete" variant="white" no-wrap>
					<template #overlay>
						<div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label" class="text-center p-3" style="min-width:280px;" >
							<p><strong id="form-confirm-label">Haluatko varmasti poistaa tilin?<br/>Tätä ei voi perua.</strong></p>
							<div class="text-center">
								<b-button variant="outline-danger" class="mr-3" @click="confirmDelete = false">Peruuta</b-button>
								<b-button variant="outline-success" @click="senddelete()">Poista tili</b-button>
							</div>
						</div>
					</template>
				</b-overlay>

			</div>
		</div>
	</div>

</template>

<script>
export default {
  data: function () {
		return {
			loading: true,
			error: false,
      showform: false,
      sending: false,
      confirmDelete:false,
			profile: []
		}
	},
	created: function () {
		var vm = this;
    vm.load();
	},
	methods: {
		load: function () {
			var vm = this;
			vm.loading = true;
      vm.showform = false;
			this.axios.get('/api/profile')
			.then(function (response) {
				if(response.status == 200) {
          vm.showform = true;
					vm.loading = false;
					vm.profile = response.data.object;
				} else {
					vm.error = response.data.message;
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.loading = false;
			})
		},
    saveProfile: function() {
      var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				this.axios({
					method: 'put',
					url: '/api/profile',
					data: vm.profile
				})
				.then(function (response) {
					if(response.data.success) {
            vm.profile = response.data.object;
						vm.$parent.makeToast('success', 'Tietojen tallennus', 'Onnistui!');
					} else {

            var errors = [];
            Object.keys(response.data.errors).forEach(function(key) {
              errors.push(response.data.errors[key])
            });
            if(errors.length > 0) {
              vm.$parent.makeToast('warning', 'Tietojen tallennus', errors.join(' ') );
            } else {
              vm.$parent.makeToast('warning', 'Tietojen tallennus', 'Täytä pakolliset kentät!');
            }
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Tietojen tallennus', 'Epäonnistui!');
				})
			}
    },
    senddelete: function() {
      var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				this.axios({
					method: 'post',
					url: '/api/profile/unregister',
					data: { accept:true }
				})
				.then(function (response) {
					if(response.data.success) {
            alert('Tili poistettu!');
            window.location.href = '/';
					} else {
						vm.$parent.makeToast('warning', 'Tilin poisto', 'Epäonnistui!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Tilin poisto', 'Epäonnistui!');
				})
			}
    }
	}
}
</script>
